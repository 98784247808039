export const SliderData = [
  {
    image: "./Images/SA01.jpg"
  },
  {
    image: "./Images/SA02.jpg"
  },
  {
    image: "./Images/SA03.jpg"
  },
  {
    image: "./Images/SA04.jpg"
  },
  {
    image: "./Images/SA05.jpg"
  },
  {
    image: "./Images/SA06.jpg"
  },
  {
    image: "./Images/SA07.jpg"
  },
  {
    image: "./Images/SA08.jpg"
  },
  {
    image: "./Images/SA09.jpg"
  },
  {
    image: "./Images/SA10.jpg"
  },
  {
    image: "./Images/SA11.jpg"
  },
  {
    image: "./Images/SA12.jpg"
  },
  {
    image: "./Images/SA13.jpg"
  },
  {
    image: "./Images/SA14.jpg"
  },
  {
    image: "./Images/SA15.jpg"
  },
  {
    image: "./Images/SA16.jpg"
  },
  {
    image: "./Images/SA17.jpg"
  },
]