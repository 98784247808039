import React from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import "../Contact/Contact.css"

function Contact() {
  return (
    <Wrapper>
      <div className="Contact">Contact Page</div>
    </Wrapper>
  );
}

export default Contact;
